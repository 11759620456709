html,
body,
p,
ul,
ol,
a,
.pure-g [class *= "pure-u"] {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12pt;
  font-weight: 300;
  line-height: 1.75em;
}

h1,
h2,
h3 {
  font-weight: 700;
  padding: 0;
}

h1 {
  text-transform: uppercase;
  font-size: 2.2em;
  margin: 5px 0 20px;
}

h2 {
  margin: 5px 0 15px;
  font-size: 1.8em;
}

h3 {
  margin: 5px 0 10px;
  font-size: 1.2em;
}

p {
  margin: 5px 0 8px;
  font-size: 16px;
}

ul.unordered {
  padding-left: 1.3em;
}

ol.ordered {
  padding-left: 1em;
}

a {
  color: #628cdb;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.wh-video {
  margin: 5px 0 25px;
}

.wh-rtd-editor {
  padding: 3px;
}

.wh-rtd-editor h1.heading1 {
  text-transform: initial;
}
